/* html css required for Unity full-page colored background */
html {
    margin: 0;
    color:white;
    background: #000000;
    background-image: url('https://images.unsplash.com/photo-1534996858221-380b92700493?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1631&q=80');
    background-size: cover;
    background-color: rgb(47, 47, 80);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
}

.column {
    margin-top: 0px;
}

/* Centered horizontally and vertically to fill the page */
.loading_page {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}


/* Cal Poly appropriate font */
.load_info {
    padding-top: 0px;
    color: rgb(255, 255, 255);
    font-family: abolition, sans-serif;
    font-weight: 100;
    font-size: 3rem;
    letter-spacing: 3px;
    font-style: italic;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    
}

/* Button */
#button_room{
  background-color: #24A0ED;
  color: white;
}

/* Title of scene highlight */
.location_highlight {
    color: #bd8b13; /* Cal Poly Gold */
}

/* Centered horizontally and vertically to fill the page */
.scene_page {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Centered horizontally and vertically */
.unity_scene {
    border-width: 3px;
    border-radius: 3px;
    border-color: #24A0ED; 
    border-style: solid;
}

/* Bar with buttons and name under scene box */
.unity_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

/* Progress bar */
.progress_shell {
    width: 100%;
    height: 40px;
    background-color: #fff;
    border-width: 3px;
    border-style: solid;
    border-color: rgb(139, 139, 139);
    border-radius: 5px;
}
.progress_fill {
    background-color: #bd8b13; /* Cal Poly Gold */
    height: 10px;
    transition: linear;
}

/* Poly logo aligned to left of border */ 
.poly_logo {
    float: left;
    padding: 11px;
    margin-left: auto;
}

/* Satelite logo aligned to right of border */
.satellite {
    float: right;
    margin-right: auto;
    margin: 18px;
    /* width: 32px;  */
}

.space_logo{
    width: 200px;
    align-self: auto;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}


.golden_header{
    /* position: left; */
    background-color: #bd8b13;
    height: 60px;

}

.txt-extra{
    color: #fff;
    font-family: abolition, sans-serif;
    font-weight: 100;
    font-size: 3rem;
    letter-spacing: 3px;
    font-style: italic;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

/* Progress Bar */
@keyframes spin {
    from {
      transform: rotate(0);
    }
    to{
      transform: rotate(359deg);
    }
  }
  
  @keyframes spin3D {
    from {
      transform: rotate3d(.5,.5,.5, 360deg);
    }
    to{
      transform: rotate3d(0,0,0);
    }
  }
  
  @keyframes configure-clockwise {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes configure-xclockwise {
    0% {
      transform: rotate(45deg);
    }
    25% {
      transform: rotate(-45deg);
    }
    50% {
      transform: rotate(-135deg);
    }
    75% {
      transform: rotate(-225deg);
    }
    100% {
      transform: rotate(-315deg);
    }
  }
  
  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .25;
      transform: scale(.75);
    }
  }

  .spinner-box {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .leo {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
  }
  
  .blue-orbit {
      width: 165px;
      height: 165px;
    border: 1px solid #91daffa5;
    -webkit-animation: spin3D 3s linear .2s infinite;
  }
  
  .green-orbit {
      width: 120px;
      height: 120px;
    border: 1px solid #91ffbfa5;
    -webkit-animation: spin3D 2s linear 0s infinite;
  }
  
  .red-orbit {
      width: 90px;
      height: 90px;
    border: 1px solid #ffca91a5;
    -webkit-animation: spin3D 1s linear 0s infinite;
  }
  
  .white-orbit {
      width: 60px;
      height: 60px;
    border: 2px solid #ffffff;
    -webkit-animation: spin3D 10s linear 0s infinite;
  }
  
  .w1 {
    transform: rotate3D(1, 1, 1, 90deg);
  }
  
  .w2 {
    transform: rotate3D(1, 2, .5, 90deg);
  }
  
  .w3 {
    transform: rotate3D(.5, 1, 2, 90deg);
  }
  